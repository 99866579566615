/// <reference path="../../typings/index.d.ts" />

$(function() {

    var places = [
        {
            center: [60.007942, 30.795848],
            name: "Всеволожск, ЖД станция Кирпичный завод",
            title: "Адрес завода"
        },
        {
            center: [59.731015, 30.107432],
            name: "Красное село, Пушкинское шоссе дом 1",
            title: "Адрес завода"
        },
        {
            center: [60.136040, 30.202285],
            name: "Сертолово, Индустриальная ул",
            title: "Адрес завода"
        },
        {
            center: [59.898810, 29.782278],
            name: "Ломоносов, ул. Федюнинского",
            title: "Адрес завода"
        },
        {
            center: [59.809364, 30.587078],
            name: "Металлострой, Петрозаводское шоссе",
            title: "Адрес завода"
        }
    ];

    ymaps.ready(function () {
        // Создание экземпляра карты и его привязка к созданному контейнеру.
        var myMap = new ymaps.Map('map', {
            center: [59.930480, 30.315181],
            zoom: 14, 
            controls:[]
            }),

        // Создание макета балуна на основе Twitter Bootstrap.
            MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
                '<div class="popover top">' +
                    '<div class="arrow"></div>' +
                    '<div class="popover-inner">' +
                    '$[[options.contentLayout observeSize minWidth=235 maxWidth=235 maxHeight=350]]' +
                    '</div>' +
                    '</div>', {
                    /**
                     * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
                     * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
                     * @function
                     * @name build
                     */
                    build: function () {
                        this.constructor.superclass.build.call(this);

                        this._$element = $('.popover', this.getParentElement());

                        this.applyElementOffset();

                        this._$element.find('.close')
                            .on('click', $.proxy(this.onCloseClick, this));
                    },

                    /**
                     * Удаляет содержимое макета из DOM.
                     * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
                     * @function
                     * @name clear
                     */
                    clear: function () {
                        this._$element.find('.close')
                            .off('click');

                        this.constructor.superclass.clear.call(this);
                    },

                    /**
                     * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
                     * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
                     * @function
                     * @name onSublayoutSizeChange
                     */
                    onSublayoutSizeChange: function () {
                        MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

                        if(!this._isElement(this._$element)) {
                            return;
                        }

                        this.applyElementOffset();

                        this.events.fire('shapechange');
                    },

                    /**
                     * Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
                     * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
                     * @function
                     * @name applyElementOffset
                     */
                    applyElementOffset: function () {
                        this._$element.css({
                            left: -(this._$element[0].offsetWidth / 2),
                            top: -(this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight)
                        });
                    },

                    /**
                     * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
                     * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
                     * @function
                     * @name onCloseClick
                     */
                    onCloseClick: function (e) {
                        e.preventDefault();

                        this.events.fire('userclose');
                    },

                    /**
                     * Используется для автопозиционирования (balloonAutoPan).
                     * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
                     * @function
                     * @name getClientBounds
                     * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
                     */
                    getShape: function () {
                        if(!this._isElement(this._$element)) {
                            return MyBalloonLayout.superclass.getShape.call(this);
                        }

                        var position = this._$element.position();

                        return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                            [position.left, position.top], [
                                position.left + this._$element[0].offsetWidth,
                                position.top + this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight
                            ]
                        ]));
                    },

                    /**
                     * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
                     * @function
                     * @private
                     * @name _isElement
                     * @param {jQuery} [element] Элемент.
                     * @returns {Boolean} Флаг наличия.
                     */
                    _isElement: function (element) {
                        return element && element[0] && element.find('.arrow')[0];
                    }
                }),

        // Создание вложенного макета содержимого балуна.
            MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
                '<div class="address_desc opened">' +
                '<a class="close" href="#">&times;</a>' +
                '<div class="orange"></div>' +
                '<div class="text">' +
                    '<span class="address_title">$[properties.balloonHeader]</span>' +
                    '<span class="address">$[properties.balloonContent]</span>' +
                '</div>' +
            '</div>'
            ),

            for (var i = 0, l = places.length; i < l; i++) {
                createPlacemarks(places[i], i);
            }



            function createPlacemarks(place,i) {
                                            
                // Создание метки с пользовательским макетом балуна.
                var myPlacemark = new ymaps.Placemark(place.center, {
                    balloonHeader: place.title,
                    balloonContent: place.name
                }, {
                    iconLayout: 'default#image', 
                    iconImageHref: 'img/ymarker.png',
                    iconImageSize: [26, 36],
                    iconImageOffset: [-13, -36],
                    balloonShadow: false,
                    balloonLayout: MyBalloonLayout,
                    balloonContentLayout: MyBalloonContentLayout,
                    balloonPanelMaxMapArea: 0,
                    // Не скрываем иконку при открытом балуне.
                    hideIconOnBalloonOpen: false,
                    // И дополнительно смещаем балун, для открытия над иконкой.
                    //balloonOffset: [-195, -144]
                });

                myMap.geoObjects.add(myPlacemark); 

                myPlacemark.events.add('balloonopen', function (e) {   
                    
                    let projection = myMap.options.get('projection');
                    
                    let new_center_px = myMap.converter.globalToPage(
                        projection.toGlobalPixels(
                            // географические координаты
                            myPlacemark.geometry.getCoordinates(),
                            myMap.getZoom()
                        )
                    );

                    let new_center = projection.fromGlobalPixels(
                        myMap.converter.pageToGlobal( [new_center_px[0]-100, new_center_px[1]-70] ), myMap.getZoom()
                    );

                    console.log(new_center_px);

                    console.log(new_center);

                    myMap.panTo(new_center, {flying: false});
                    $('.ymap .address_desc').addClass('opened');
                });
            }

            // Выставляем масштаб карты чтобы были видны все группы.
            myMap.setBounds(myMap.geoObjects.getBounds());
    });

    

});
